import { ApolloQueryResult } from '@apollo/client'
import { AxiosError } from 'axios'
import Cookies from 'cookies'
import {
  GetPartnerIdByLoginChallengeDocument,
  GetPartnerIdByLoginChallengeQuery,
  GetUserKratosCredentialsQuery,
  GetUserKratosCredentialsDocument
} from 'graph/generated/payments/graphql-types'
import type { GetServerSideProps, NextPage } from 'next'
import { AppCookie, getCookieExpires } from 'utils/cookies'

import AccessYourAccountLayout from '@/layouts/AccessYourAccountLayout'
import LoginLayout from '@/layouts/LoginLayout'
import { authSSRClient } from '@/utils/apollo'
import ory from '@/utils/ory/index'
import { oauth2Api } from '@/utils/ory/index'

const LoginPage: NextPage = ({
  email,
  loginChallenge,
  flow,
  credentials = []
}: {
  email?: string
  loginChallenge?: string
  flow?: string
  credentials?: string[]
}) => {
  if (email || flow) {
    return <LoginLayout userEmail={email} credentials={credentials} />
  }

  return <AccessYourAccountLayout loginChallenge={loginChallenge} />
}

async function getUserKratosCredentials(identifier: string) {
  let credentials = []
  try {
    const { data }: ApolloQueryResult<GetUserKratosCredentialsQuery> =
      await authSSRClient.query({
        query: GetUserKratosCredentialsDocument,
        variables: {
          identifier
        }
      })
    credentials = data?.GetUserKratosCredentials.credentials || []
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting user credentials', error)
  }

  return credentials
}

async function getPartnerIdByLoginChallenge(loginChallenge: string) {
  const { data }: ApolloQueryResult<GetPartnerIdByLoginChallengeQuery> =
    await authSSRClient.query({
      query: GetPartnerIdByLoginChallengeDocument,
      variables: {
        loginChallenge
      }
    })

  return data?.GetPartnerIdByLoginChallenge
}

const skipGoBackByCookies = cookies =>
  String(!cookies.get(AppCookie.PartnerIdAuth))

// redirect if already logged in
export const getServerSideProps: GetServerSideProps = async ({
  query,
  req,
  res
}) => {
  const cookies = new Cookies(req, res)
  const loginChallengeCookie = cookies.get(AppCookie.LoginChallenge)

  const loginChallenge =
    (query?.login_challenge as string) || loginChallengeCookie
  const email = (query?.email as string) || null
  const flow = (query?.flow as string) || null

  if (!loginChallenge) {
    return {
      props: {},
      redirect: {
        destination: `/error?skip_go_back=${skipGoBackByCookies(
          cookies
        )}&message=Login+challenge+missing.+Login+from+your+partner+or+customer+dashboard`,
        permanent: false
      }
    }
  }

  if (loginChallenge && String(loginChallenge).length <= 32) {
    return {
      redirect: {
        destination: `/error?skip_go_back=${skipGoBackByCookies(
          cookies
        )}&title=Old+version+detected&message=You+bookmarked+an+old+version+of+the+portal.+Delete+it+and+save+a+new+one+from+your+partner+or+customer+dashboard`,
        permanent: false
      }
    }
  }

  cookies.set(AppCookie.LoginChallenge, loginChallenge, {
    expires: getCookieExpires(AppCookie.LoginChallenge),
    httpOnly: false
  })

  try {
    const partnerId = await getPartnerIdByLoginChallenge(loginChallenge)

    if (partnerId && partnerId !== 'gateway') {
      cookies.set(AppCookie.PartnerIdAuth, partnerId, {
        expires: getCookieExpires(AppCookie.PartnerIdAuth),
        httpOnly: false
      })
    } else {
      cookies.set(AppCookie.PartnerIdAuth, '', {
        expires: new Date(0)
      })
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error calling getPartnerIdByLoginChallenge', err)
    cookies.set(AppCookie.LoginChallenge, '', {
      expires: new Date(0)
    })
  }

  try {
    const { data: loginRequest } = await oauth2Api.getOAuth2LoginRequest({
      loginChallenge
    })

    if (loginRequest.skip) {
      const session = await ory
        .toSession({
          cookie: req.headers.cookie
        })
        .catch(() => null)

      const { data: acceptLogin } = await oauth2Api.acceptOAuth2LoginRequest({
        loginChallenge,
        acceptOAuth2LoginRequest: {
          subject: loginRequest.subject,
          acr: session?.data?.authenticator_assurance_level
        }
      })

      return {
        props: {},
        redirect: {
          destination: acceptLogin.redirect_to
        }
      }
    }
  } catch (err) {
    const axiosError = err as AxiosError

    if (axiosError.response?.status === 404) {
      return {
        redirect: {
          destination: `/error?skip_go_back=${skipGoBackByCookies(
            cookies
          )}&message=Login+challenge+not+found.+Login+from+your+partner+or+customer+dashboard`,
          permanent: false
        }
      }
    }

    if (axiosError.response?.status === 410) {
      return {
        props: {},
        redirect: {
          destination: (axiosError.response.data as { redirect_to: string })
            .redirect_to
        }
      }
    }
    // eslint-disable-next-line no-console
    console.error('Error accepting login request', err)
  }

  if (email) {
    const credentials = await getUserKratosCredentials(email)

    return {
      props: {
        email,
        loginChallenge,
        flow,
        credentials
      }
    }
  }

  return {
    props: {
      email,
      loginChallenge,
      flow
    }
  }
}

export default LoginPage
